import { Interface } from 'ethers'
import ABI from './ABI.js'

export default class CustomErrors {
  constructor () {
    this.errorsInterface = new Interface(ABI.CustomErrors)
  }

  parseError (error) {
    if (error.data) {
      error.parsedData = this.errorsInterface.parseError(error.data)
    }
  }
}
