import { ethers } from 'ethers'

import Amount from './data/Amount.js'
import {
  MAX_EPOCH_TARGET,
  ONE_DAY,
  ONE_QODA,
  isEqualString,
  nowInSeconds,
  pow10Exponent
} from './helpers.js'

// READ

//
// Get the price of QODA in USDC.
//
export function getPriceOfQoda ({ logger, usdcToken, qodaToken, qodaUsdcUniV2Token }) {
  return async () => {
    const usdcDecimals = await usdcToken.decimals()
    const usdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, ONE_QODA)

    logger.debug('getPriceOfQoda', { usdcDecimals, usdc })

    return new Amount(usdcDecimals, usdc)
  }
}

//
// Get the QODA balance and its estimated USDC amount for the given address.
//
export function getQodaBalance ({ logger, usdcToken, qodaToken, qodaUsdcUniV2Token }) {
  return async (address, includeUsdc = false) => {
    const qodaDecimals = await qodaToken.decimals()
    const qodaBalance = await qodaToken.balanceOf(address)

    if (includeUsdc) {
      const usdcDecimals = await usdcToken.decimals()
      const qodaBalanceUsdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, qodaBalance)

      logger.debug(`getQodaBalance(${address}, includeUsdc = true)`, { qodaDecimals, qodaBalance, usdcDecimals, qodaBalanceUsdc })

      return {
        balance: new Amount(qodaDecimals, qodaBalance),
        balanceUSDC: new Amount(usdcDecimals, qodaBalanceUsdc)
      }
    }

    logger.debug(`getQodaBalance(${address}, includeUsdc = false)`, { qodaDecimals, qodaBalance })

    return {
      balance: new Amount(qodaDecimals, qodaBalance)
    }
  }
}

//
// Get the QODA staked balance and its estimated USDC amount for the given address.
//
export function getQodaStakedBalance ({ logger, usdcToken, qodaToken, veQoda, qodaUsdcUniV2Token }) {
  return async (address, includeUsdc = false) => {
    const qodaDecimals = await qodaToken.decimals()
    const userStakingInfo = await veQoda.userStakingInfo(address, await veQoda.stakeVanilla())
    const qodaStakedBalance = userStakingInfo.amount

    if (includeUsdc) {
      const usdcDecimals = await usdcToken.decimals()
      const qodaStakedBalanceUsdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, qodaStakedBalance)

      logger.debug(`getQodaStakedBalance(${address}, includeUsdc = true)`, { qodaDecimals, qodaStakedBalance, usdcDecimals, qodaStakedBalanceUsdc })

      return {
        balance: new Amount(qodaDecimals, qodaStakedBalance),
        balanceUSDC: new Amount(usdcDecimals, qodaStakedBalanceUsdc)
      }
    }

    logger.debug(`getQodaStakedBalance(${address}, includeUsdc = false)`, { qodaDecimals, qodaStakedBalance })

    return {
      balance: new Amount(qodaDecimals, qodaStakedBalance)
    }
  }
}

//
// Get the QODA/USDC UNI-V2 balance, its QODA and USDC portions, and its estimated USDC amount for the given address.
//
export function getQodaUsdcUniV2Balance ({ logger, usdcToken, qodaToken, qodaUsdcUniV2Token }) {
  return async (address, includeBreakdown = false) => {
    const uniV2Decimals = await qodaUsdcUniV2Token.decimals()
    const uniV2 = await qodaUsdcUniV2Token.balanceOf(address)

    if (includeBreakdown) {
      const qodaDecimals = await qodaToken.decimals()
      const usdcDecimals = await usdcToken.decimals()

      const uniV2TotalSupply = await qodaUsdcUniV2Token.totalSupply()
      // The QODA portion
      const qoda = uniV2TotalSupply === 0n ? 0n : (await qodaToken.balanceOf(qodaUsdcUniV2Token.address)) * uniV2 / uniV2TotalSupply
      // The USDC portion
      const usdc = uniV2TotalSupply === 0n ? 0n : (await usdcToken.balanceOf(qodaUsdcUniV2Token.address)) * uniV2 / uniV2TotalSupply

      const qodaUsdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, qoda)
      // The estimated USDC value
      const uniV2Usdc = usdc + qodaUsdc

      logger.debug(`getQodaUsdcUniV2Balance(${address}, includeBreakdown = true)`, {
        uniV2TotalSupply,
        uniV2Decimals,
        uniV2,
        qodaDecimals,
        qoda,
        usdcDecimals,
        usdc,
        uniV2Usdc
      })

      return {
        uniV2: new Amount(uniV2Decimals, uniV2),
        qoda: new Amount(qodaDecimals, qoda),
        usdc: new Amount(usdcDecimals, usdc),
        uniV2Usdc: new Amount(usdcDecimals, uniV2Usdc)
      }
    }

    logger.debug(`getQodaUsdcUniV2Balance(${address}, includeBreakdown = false)`, { uniV2Decimals, uniV2 })

    return {
      uniV2: new Amount(uniV2Decimals, uniV2)
    }
  }
}

//
// Get the QODA/USDC UNI-V2 staked balance, its QODA and USDC portions, and its estimated USDC amount for the given address.
//
export function getQodaUsdcUniV2StakedBalance ({ logger, usdcToken, qodaToken, veQoda, qodaUsdcUniV2Token }) {
  return async (address, includeBreakdown = false) => {
    const uniV2Decimals = await qodaUsdcUniV2Token.decimals()

    const userStakingInfo = await veQoda.userStakingInfo(address, await veQoda.stakeLiquidityPool())
    const uniV2 = userStakingInfo.amount

    if (includeBreakdown) {
      const qodaDecimals = await qodaToken.decimals()
      const usdcDecimals = await usdcToken.decimals()

      const uniV2TotalSupply = await qodaUsdcUniV2Token.totalSupply()
      // The QODA portion
      const qoda = uniV2TotalSupply === 0n ? 0n : (await qodaToken.balanceOf(qodaUsdcUniV2Token.address)) * uniV2 / uniV2TotalSupply
      // The USDC portion
      const usdc = uniV2TotalSupply === 0n ? 0n : (await usdcToken.balanceOf(qodaUsdcUniV2Token.address)) * uniV2 / uniV2TotalSupply

      const qodaUsdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, qoda)
      // The estimated USDC value
      const uniV2Usdc = usdc + qodaUsdc

      logger.debug(`getQodaUsdcUniV2StakedBalance(${address}, includeBreakdown = true)`, {
        uniV2TotalSupply,
        uniV2Decimals,
        uniV2,
        qodaDecimals,
        qoda,
        usdcDecimals,
        usdc,
        uniV2Usdc
      })

      return {
        uniV2: new Amount(uniV2Decimals, uniV2),
        qoda: new Amount(qodaDecimals, qoda),
        usdc: new Amount(usdcDecimals, usdc),
        uniV2Usdc: new Amount(usdcDecimals, uniV2Usdc)
      }
    }

    logger.debug(`getQodaUsdcUniV2StakedBalance(${address}, includeBreakdown = false)`, { uniV2Decimals, uniV2 })

    return {
      uniV2: new Amount(uniV2Decimals, uniV2)
    }
  }
}

//
// Get the QODA/WETH UNI-V2 balance, its QODA and WETH portions, and its estimated USDC amount for the given address.
//
export function getQodaWethUniV2Balance ({ logger, usdcToken, qodaToken, wethToken, qodaUsdcUniV2Token, qodaWethUniV2Token }) {
  return async (address, includeBreakdown = false) => {
    const uniV2Decimals = await qodaWethUniV2Token.decimals()
    const uniV2 = await qodaWethUniV2Token.balanceOf(address)

    if (includeBreakdown) {
      const qodaDecimals = await qodaToken.decimals()
      const usdcDecimals = await usdcToken.decimals()
      const wethDecimals = await wethToken.decimals()

      const uniV2TotalSupply = await qodaWethUniV2Token.totalSupply()
      // The QODA portion
      const qoda = uniV2TotalSupply === 0n ? 0n : (await qodaToken.balanceOf(qodaWethUniV2Token.address)) * uniV2 / uniV2TotalSupply
      // The WETH portion
      const weth = uniV2TotalSupply === 0n ? 0n : (await wethToken.balanceOf(qodaWethUniV2Token.address)) * uniV2 / uniV2TotalSupply

      const qodaUsdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, qoda)
      const wethQoda = await wethToQoda(logger, qodaToken, wethToken, qodaWethUniV2Token, weth)
      const wethUsdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, wethQoda)

      // The estimated USDC value
      const uniV2Usdc = qodaUsdc + wethUsdc

      logger.debug(`getQodaWethUniV2Balance(${address}, includeBreakdown = true)`, {
        uniV2TotalSupply,
        uniV2Decimals,
        uniV2,
        qodaDecimals,
        qoda,
        wethDecimals,
        weth,
        qodaUsdc,
        wethQoda,
        wethUsdc,
        uniV2Usdc
      })

      return {
        uniV2: new Amount(uniV2Decimals, uniV2),
        qoda: new Amount(qodaDecimals, qoda),
        weth: new Amount(wethDecimals, weth),
        uniV2Usdc: new Amount(usdcDecimals, uniV2Usdc)
      }
    }

    logger.debug(`getQodaWethUniV2Balance(${address}, includeBreakdown = false)`, { uniV2Decimals, uniV2 })

    return {
      uniV2: new Amount(uniV2Decimals, uniV2)
    }
  }
}

//
// Get the QODA/WETH UNI-V2 staked balance, its QODA and WETH portions, and its estimated USDC amount for the given address.
//
export function getQodaWethUniV2StakedBalance ({ logger, usdcToken, qodaToken, wethToken, veQoda, qodaUsdcUniV2Token, qodaWethUniV2Token }) {
  return async (address, includeBreakdown = false) => {
    const uniV2Decimals = await qodaWethUniV2Token.decimals()

    const userStakingInfo = await veQoda.userStakingInfo(address, veQoda.stakeLiquidityPoolWeth())
    const uniV2 = userStakingInfo.amount

    if (includeBreakdown) {
      const qodaDecimals = await qodaToken.decimals()
      const usdcDecimals = await usdcToken.decimals()
      const wethDecimals = await wethToken.decimals()

      const uniV2TotalSupply = await qodaWethUniV2Token.totalSupply()
      // The QODA portion
      const qoda = uniV2TotalSupply === 0n ? 0n : (await qodaToken.balanceOf(qodaWethUniV2Token.address)) * uniV2 / uniV2TotalSupply
      // The WETH portion
      const weth = uniV2TotalSupply === 0n ? 0n : (await wethToken.balanceOf(qodaWethUniV2Token.address)) * uniV2 / uniV2TotalSupply

      const qodaUsdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, qoda)
      const wethQoda = await wethToQoda(logger, qodaToken, wethToken, qodaWethUniV2Token, weth)
      const wethUsdc = await qodaToUsdc(logger, usdcToken, qodaToken, qodaUsdcUniV2Token, wethQoda)

      // The estimated USDC value
      const uniV2Usdc = qodaUsdc + wethUsdc

      logger.debug(`getQodaWethUniV2StakedBalance(${address}, includeBreakdown = true)`, {
        uniV2TotalSupply,
        uniV2Decimals,
        uniV2,
        qodaDecimals,
        qoda,
        wethDecimals,
        weth,
        qodaUsdc,
        wethQoda,
        wethUsdc,
        uniV2Usdc
      })

      return {
        uniV2: new Amount(uniV2Decimals, uniV2),
        qoda: new Amount(qodaDecimals, qoda),
        weth: new Amount(wethDecimals, weth),
        uniV2Usdc: new Amount(usdcDecimals, uniV2Usdc)
      }
    }

    logger.debug(`getQodaWethUniV2StakedBalance(${address}, includeBreakdown = false)`, { uniV2Decimals, uniV2 })

    return {
      uniV2: new Amount(uniV2Decimals, uniV2)
    }
  }
}

async function qodaToUsdc (logger, usdcToken, qodaToken, qodaUsdcUniV2Token, qodaAmount) {
  const [reserves, token0, token1] = await Promise.all([
    qodaUsdcUniV2Token.getReserves(),
    qodaUsdcUniV2Token.token0(),
    qodaUsdcUniV2Token.token1()
  ])

  let totalQoda = 1n
  let totalUsdc = 0n

  if (isEqualString(token0, usdcToken.address) && isEqualString(token1, qodaToken.address)) {
    totalUsdc = reserves.reserve0
    totalQoda = reserves.reserve1
  } else if (isEqualString(token0, qodaToken.address) && isEqualString(token1, usdcToken.address)) {
    totalQoda = reserves.reserve0
    totalUsdc = reserves.reserve1
  }

  logger.debug('qodaToUsdc', { totalQoda, totalUsdc })

  return qodaAmount * totalUsdc / totalQoda
}

async function wethToQoda (logger, qodaToken, wethToken, qodaWethUniV2Token, wethAmount) {
  const [reserves, token0, token1] = await Promise.all([
    qodaWethUniV2Token.getReserves(),
    qodaWethUniV2Token.token0(),
    qodaWethUniV2Token.token1()
  ])

  let totalQoda = 0n
  let totalWeth = 1n

  if (isEqualString(token0, qodaToken.address) && isEqualString(token1, wethToken.address)) {
    totalQoda = reserves.reserve0
    totalWeth = reserves.reserve1
  } else if (isEqualString(token0, wethToken.address) && isEqualString(token1, qodaToken.address)) {
    totalWeth = reserves.reserve0
    totalQoda = reserves.reserve1
  }

  logger.debug('wethToQoda', { totalQoda, totalWeth })

  return wethAmount * totalQoda / totalWeth
}

//
// Get the base rate for the vanilla staking method.
//
export function getVanillaBaseRate ({ logger, veQoda }) {
  return async () => {
    return await getBaseRate(logger, veQoda, 'Vanilla', await veQoda.stakeVanilla())
  }
}

//
// Get the base rate for the QODA/USDC liquidity pool staking method.
//
export function getQodaUsdcUniV2BaseRate ({ logger, veQoda }) {
  return async () => {
    return await getBaseRate(logger, veQoda, 'QodaUsdcUniV2', await veQoda.stakeLiquidityPool())
  }
}

//
// Get the base rate for the QODA/WETH liquidity pool staking method.
//
export function getQodaWethUniV2BaseRate ({ logger, veQoda }) {
  return async () => {
    return await getBaseRate(logger, veQoda, 'QodaWethUniV2', await veQoda.stakeLiquidityPoolWeth())
  }
}

async function getBaseRate (logger, veQoda, name, method) {
  const scaleFactorVePerDay = await veQoda.scaleFactorVePerDay()
  const veDecimals = pow10Exponent(scaleFactorVePerDay)

  const methodInfo = await veQoda.methodInfo(method)
  const veEmittedPerDay = methodInfo.veEmittedPerDay.length > 0 ? methodInfo.veEmittedPerDay[0] : 0n

  logger.debug(`get${name}BaseRate`, { scaleFactorVePerDay, methodInfo, veDecimals, veEmittedPerDay })

  return new Amount(veDecimals, veEmittedPerDay)
}

//
// Get veQODA accrual for the vanilla staking method.
//
export function getVeQodaAccrualVanillaMethod ({ logger, veQoda }) {
  return async (address) => {
    return await getVeQodaAccrual(logger, veQoda, address, 'Vanilla', await veQoda.stakeVanilla())
  }
}

//
// Get veQODA accrual for the QODA/USDC liquidity pool staking method.
//
export function getVeQodaAccrualQodaUsdcUniV2 ({ logger, veQoda }) {
  return async (address) => {
    return await getVeQodaAccrual(logger, veQoda, address, 'QodaUsdcUniV2', await veQoda.stakeLiquidityPool())
  }
}

//
// Get veQODA accrual for the QODA/WETH liquidity pool staking method.
//
export function getVeQodaAccrualQodaWethUniV2 ({ logger, veQoda }) {
  return async (address) => {
    return await getVeQodaAccrual(logger, veQoda, address, 'QodaWethUniV2', await veQoda.stakeLiquidityPoolWeth())
  }
}

async function getVeQodaAccrual (logger, veQoda, address, name, method) {
  const veDecimals = await veQoda.decimals()
  const scaleFactorVePerDay = await veQoda.scaleFactorVePerDay()

  const methodInfo = await veQoda.methodInfo(method)
  const veEmittedPerDay = methodInfo.veEmittedPerDay.length > 0 ? methodInfo.veEmittedPerDay[0] : 0n

  const { amount } = await veQoda.userStakingInfo(address, method)
  const accrual = scaleFactorVePerDay === 0n ? 0n : amount * veEmittedPerDay / scaleFactorVePerDay

  logger.debug(`getVeQodaAccrual${name}Method`, { veDecimals, accrual })

  return new Amount(veDecimals, accrual)
}

//
// Get veQODA ownership information for the given address.
//
export function getVeQodaOwnership ({ logger, veQoda }) {
  return async (address) => {
    const veDecimals = await veQoda.decimals()

    const today = nowInSeconds()
    const tomorrow = today + ONE_DAY

    const veBalanceToday = await veQoda.accountVe(address, today)
    const veBalanceTomorrow = await veQoda.accountVe(address, tomorrow)

    const veTotalSupply = await veQoda.totalVe(today)
    // Multiply by 100 (for percent)
    // Multiple by 10000 (for 4 decimal places)
    const veOwnership = veBalanceToday * 1000000n / veTotalSupply

    //
    // The suggested accrual (per day) calculation.
    //
    const accrual = veBalanceTomorrow - veBalanceToday

    logger.debug(`getVeQodaOwnership(${address})`, {
      today,
      tomorrow,
      veDecimals,
      veTotalSupply,
      veOwnership,
      veBalanceToday,
      veBalanceTomorrow,
      accrual
    })

    return {
      ownership: veOwnership,
      accrual: new Amount(veDecimals, accrual),
      balance: new Amount(veDecimals, veBalanceToday),
      totalSupply: new Amount(veDecimals, veTotalSupply)
    }
  }
}

//
// Get the unclaimed, but available, QODA rewards for the given address.
//
export function getUnclaimedQodaRewards ({ rewardDistributor }) {
  return async (address) => {
    return await rewardDistributor.getUnclaimedReward(address, MAX_EPOCH_TARGET)
  }
}

//
// TODO: View details about the current epoch: epoch number,
//       countdown timer until it ends, total rewards available
//       to all stakers for the epoch in $QODA tokens.
//

// WRITE

//
// Approve veQODA to transfer QODA (only if necessary).
//
export function approveVeQodaToTransferQoda ({ logger, qodaToken, veQoda }) {
  return async (signer, rawAmount) => {
    const amount = ethers.getBigInt(rawAmount)
    const allowance = await qodaToken.allowance(signer.address, veQoda.address)

    logger.debug('approveVeQodaToTransferQoda', {
      owner: signer.address,
      spender: veQoda.address,
      allowance,
      amount
    })

    if (amount > allowance) {
      logger.debug('Approval is needed')
      return await qodaToken.approve(signer, veQoda.address, amount)
    }

    logger.debug('No approval is needed')
    return null
  }
}

//
// Approve veQODA to transfer UNI-V2 (only if necessary).
//
export function approveVeQodaToTransferUniV2 ({ logger, veQoda }) {
  return async (signer, uniV2Token, rawAmount) => {
    const amount = ethers.getBigInt(rawAmount)
    const allowance = await uniV2Token.allowance(signer.address, veQoda.address)

    logger.debug('approveVeQodaToTransferUniV2', {
      owner: signer.address,
      spender: veQoda.address,
      allowance,
      amount
    })

    if (amount > allowance) {
      logger.debug('Approval is needed')
      return await uniV2Token.approve(signer, veQoda.address, amount)
    }

    logger.debug('No approval is needed')
    return null
  }
}

//
// Stake QODA.
//
export function stakeQoda ({ veQoda }) {
  return async (signer, address, amount) => {
    const method = await veQoda.stakeVanilla()

    return await veQoda.stake(signer, address, method, amount)
  }
}

//
// Unstake QODA.
//
export function unstakeQoda ({ veQoda }) {
  return async (signer, amount) => {
    const method = await veQoda.stakeVanilla()

    return await veQoda.unstake(signer, method, amount)
  }
}

//
// Stake QODA/USDC UNI-V2.
//
export function stakeQodaUsdcUniV2 ({ veQoda }) {
  return async (signer, address, amount) => {
    const method = await veQoda.stakeLiquidityPool()

    return await veQoda.stake(signer, address, method, amount)
  }
}

//
// Stake QODA/WETH UNI-V2.
//
export function stakeQodaWethUniV2 ({ veQoda }) {
  return async (signer, address, amount) => {
    const method = await veQoda.stakeLiquidityPoolWeth()

    return await veQoda.stake(signer, address, method, amount)
  }
}

//
// Unstake QODA/USDC UNI-V2.
//
export function unstakeQodaUsdcUniV2 ({ veQoda }) {
  return async (signer, amount) => {
    const method = await veQoda.stakeLiquidityPool()

    return await veQoda.unstake(signer, method, amount)
  }
}

//
// Unstake QODA/WETH UNI-V2.
//
export function unstakeQodaWethUniV2 ({ veQoda }) {
  return async (signer, amount) => {
    const method = await veQoda.stakeLiquidityPoolWeth()

    return await veQoda.unstake(signer, method, amount)
  }
}

//
// Claim my unclaimed, but available, QODA rewards.
//
export function claimReward ({ rewardDistributor }) {
  return async (signer, address) => {
    return await rewardDistributor.claimReward(signer, address, MAX_EPOCH_TARGET)
  }
}
