/* global HTMLElement, customElements */

class DynamicText extends HTMLElement {
  static observedAttributes = ['value']

  constructor () {
    super()

    const style = document.createElement('style')
    style.textContent = `
        :host(:state(updated)) #value-wrapper {
          animation: flash var(--dynamic-text-animation-duration, 5000ms);
        }

        @keyframes flash {
          from {
            color: var(--dynamic-text-color, green)
          }
        }
      `

    const valueWrapper = document.createElement('span')
    valueWrapper.id = 'value-wrapper'
    valueWrapper.textContent = this.getAttribute('value')

    const root = this.attachShadow({ mode: 'open' })
    root.appendChild(style)
    root.appendChild(valueWrapper)

    this.internals = this.attachInternals()
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this)
    this.valueWrapper = valueWrapper
    this.animationStarted = false
  }

  attributeChangedCallback (name, oldValue, newValue) {
    if (name === 'value') {
      if (oldValue !== null && oldValue !== newValue) {
        if (!this.animationStarted) {
          this.animationStarted = true
          this.internals.states.add('updated')
          this.valueWrapper.addEventListener(
            'animationend',
            this.handleAnimationEnd
          )
        }
      }

      this.valueWrapper.textContent = newValue
    }
  }

  handleAnimationEnd () {
    if (this.animationStarted) {
      this.animationStarted = false
      this.internals.states.delete('updated')
      this.valueWrapper.removeEventListener(
        'animationend',
        this.handleAnimationEnd
      )
    }
  }
}

customElements.define('dynamic-text', DynamicText)
