import { ethers } from 'ethers'

import ConsoleLogger from './ConsoleLogger.js'
import CurrencyConverter from './CurrencyConverter.js'
import CustomErrors from './CustomErrors.js'
import {
  approveVeQodaToTransferQoda,
  approveVeQodaToTransferUniV2,
  claimReward,
  stakeQoda,
  stakeQodaUsdcUniV2,
  stakeQodaWethUniV2,
  unstakeQoda,
  unstakeQodaUsdcUniV2,
  unstakeQodaWethUniV2,
  getQodaUsdcUniV2BaseRate,
  getQodaWethUniV2BaseRate,
  getPriceOfQoda,
  getQodaBalance,
  getQodaUsdcUniV2Balance,
  getQodaUsdcUniV2StakedBalance,
  getQodaWethUniV2Balance,
  getQodaWethUniV2StakedBalance,
  getQodaStakedBalance,
  getUnclaimedQodaRewards,
  getVanillaBaseRate,
  getVeQodaAccrualVanillaMethod,
  getVeQodaAccrualQodaUsdcUniV2,
  getVeQodaAccrualQodaWethUniV2,
  getVeQodaOwnership
} from './useCases.js'
import initConfig from './config.js'
import QodaToken from './contracts/QodaToken.js'
import RewardDistributor from './contracts/RewardDistributor.js'
import UniswapV2Router02 from './contracts/UniswapV2Router02.js'
import USDCToken from './contracts/USDCToken.js'
import VeQoda from './contracts/VeQoda.js'
import WETHToken from './contracts/WETHToken.js'

export default class SDK {
  constructor () {
    try {
      /* global BUILD_ENV */
      this.buildEnv = BUILD_ENV
    } catch (_) {
      this.buildEnv = null
    }
  }

  async init (env, network) {
    const config = initConfig(env, network)

    this.config = config
    this.configInfo = { env, network }

    this.logger = new ConsoleLogger(config.logLevel)
    this.jsonRpcProvider = new ethers.JsonRpcProvider(config.rpcUrl)

    this.browserProviderInfo = null
    this.ethereum = null
    this.browserProvider = null

    const qodaToken = new QodaToken(this.jsonRpcProvider, this.config.contracts.qodaToken)

    let rewardDistributor = null
    if (this.config.contracts.rewardDistributor) {
      rewardDistributor = new RewardDistributor(this.jsonRpcProvider, this.config.contracts.rewardDistributor)
    }

    const usdcToken = new USDCToken(this.jsonRpcProvider, this.config.contracts.usdcToken)
    const wethToken = new WETHToken(this.jsonRpcProvider, this.config.contracts.wethToken)
    const veQoda = new VeQoda(this.jsonRpcProvider, this.config.contracts.veQoda)

    const uniswapV2Router02 = new UniswapV2Router02(this.jsonRpcProvider, this.config.contracts.uniswapV2Router02)
    const currencyConverter = new CurrencyConverter(uniswapV2Router02)

    const uniswapFactory = await uniswapV2Router02.factory()
    const qodaUsdcUniV2Token = await uniswapFactory.getPair(qodaToken.address, usdcToken.address)
    const qodaWethUniV2Token = await uniswapFactory.getPair(qodaToken.address, wethToken.address)

    const dependencies = {
      logger: this.logger,
      currencyConverter,
      qodaUsdcUniV2Token,
      qodaWethUniV2Token,
      qodaToken,
      rewardDistributor,
      usdcToken,
      wethToken,
      veQoda
    }

    this.customErrors = new CustomErrors()

    this.qodaToken = qodaToken
    this.rewardDistributor = rewardDistributor
    this.usdcToken = usdcToken
    this.veQoda = veQoda

    this.uniswapV2Router02 = uniswapV2Router02
    this.currencyConverter = currencyConverter

    this.uniswapFactory = uniswapFactory
    this.qodaUsdcUniV2Token = qodaUsdcUniV2Token
    this.qodaWethUniV2Token = qodaWethUniV2Token

    this.dependencies = dependencies

    this.getPriceOfQoda = getPriceOfQoda(dependencies)

    this.getQodaBalance = getQodaBalance(dependencies)
    this.getQodaStakedBalance = getQodaStakedBalance(dependencies)
    this.getVanillaBaseRate = getVanillaBaseRate(dependencies)
    this.stakeQoda = stakeQoda(dependencies)
    this.unstakeQoda = unstakeQoda(dependencies)

    this.getQodaUsdcUniV2Balance = getQodaUsdcUniV2Balance(dependencies)
    this.getQodaUsdcUniV2StakedBalance = getQodaUsdcUniV2StakedBalance(dependencies)
    this.getQodaUsdcUniV2BaseRate = getQodaUsdcUniV2BaseRate(dependencies)
    this.stakeQodaUsdcUniV2 = stakeQodaUsdcUniV2(dependencies)
    this.unstakeQodaUsdcUniV2 = unstakeQodaUsdcUniV2(dependencies)

    this.getQodaWethUniV2Balance = getQodaWethUniV2Balance(dependencies)
    this.getQodaWethUniV2StakedBalance = getQodaWethUniV2StakedBalance(dependencies)
    this.getQodaWethUniV2BaseRate = getQodaWethUniV2BaseRate(dependencies)
    this.stakeQodaWethUniV2 = stakeQodaWethUniV2(dependencies)
    this.unstakeQodaWethUniV2 = unstakeQodaWethUniV2(dependencies)

    this.getVeQodaAccrualVanillaMethod = getVeQodaAccrualVanillaMethod(dependencies)
    this.getVeQodaAccrualQodaUsdcUniV2 = getVeQodaAccrualQodaUsdcUniV2(dependencies)
    this.getVeQodaAccrualQodaWethUniV2 = getVeQodaAccrualQodaWethUniV2(dependencies)

    this.getVeQodaOwnership = getVeQodaOwnership(dependencies)
    this.getUnclaimedQodaRewards = getUnclaimedQodaRewards(dependencies)
    this.claimReward = claimReward(dependencies)

    this.approveVeQodaToTransferQoda = approveVeQodaToTransferQoda(dependencies)
    this.approveVeQodaToTransferUniV2 = approveVeQodaToTransferUniV2(dependencies)
  }

  getJsonRpcSigner (privateKey) {
    return new ethers.Wallet(privateKey, this.jsonRpcProvider)
  }

  getBrowserSigner () {
    return this.browserProvider.getSigner()
  }

  setLogLevel (rawLogLevel) {
    this.logger.setLogLevel(rawLogLevel)
  }

  setBrowserProvider ({ info, provider }) {
    this.browserProviderInfo = info

    this.ethereum = provider
    this.browserProvider = new ethers.BrowserProvider(provider)
  }
}
