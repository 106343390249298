import { id } from 'ethers'

import memoize from 'lodash/memoize.js'
import ABI from '../ABI.js'
import ERC20 from './ERC20.js'

export default class VeQoda extends ERC20 {
  constructor (provider, address) {
    super(provider, address, ABI.VeQoda)

    this.scaleFactorVePerDay = memoize(this.scaleFactorVePerDay)
    this.stakeLiquidityPool = memoize(this.stakeLiquidityPool)
    this.stakeVanilla = memoize(this.stakeVanilla)
  }

  // Read

  async scaleFactorVePerDay () {
    return await this.contract.SCALE_FACTOR_VE_PER_DAY()
  }

  async stakeLiquidityPool () {
    return await this.contract.STAKE_LIQUIDITY_POOL()
  }

  stakeLiquidityPoolWeth () {
    return id('LIQUIDITY_POOL_WETH')
  }

  async stakeVanilla () {
    return await this.contract.STAKE_VANILLA()
  }

  async accountVe (account, timestamp) {
    return await this.contract.accountVe(account, timestamp)
  }

  async methodInfo (method) {
    const response = await this.contract.methodInfo(method)

    return {
      token: response[0],
      decimals: response[1],
      stakedAmount: response[2],
      veEmittedPerDay: response[3],
      vePerDayEffectiveTime: response[4]
    }
  }

  async methods () {
    return await this.contract.methods()
  }

  async totalVe (timestamp) {
    return await this.contract.totalVe(timestamp)
  }

  async userStakingInfo (account, method) {
    const response = await this.contract.userStakingInfo(account, method)

    return {
      amount: response[0],
      amountVe: response[1],
      lastUpdateSec: response[2]
    }
  }

  // Write

  async stake (signer, account, method, amount) {
    const contract = this.contract.connect(signer)
    const tx = await contract.stake(account, method, amount)

    return await tx.wait()
  }

  async unstake (signer, method, amount) {
    const contract = this.contract.connect(signer)
    const tx = await contract.unstake(method, amount)

    return await tx.wait()
  }
}
