const ERROR = 30
const INFO = 20
const DEBUG = 10

export default class ConsoleLogger {
  constructor (rawLogLevel) {
    this.setLogLevel(rawLogLevel)
  }

  setLogLevel (rawLogLevel) {
    this.logLevel = rawLogLevel

    if (rawLogLevel === 'ERROR') {
      this.level = ERROR
    } else if (rawLogLevel === 'INFO') {
      this.level = INFO
    } else if (rawLogLevel === 'DEBUG') {
      this.level = DEBUG
    } else {
      this.logLevel = 'DEBUG'
      this.level = DEBUG
    }
  }

  error (...args) {
    if (this.level <= ERROR) {
      console.error(...args)
    }
  }

  info (...args) {
    if (this.level <= INFO) {
      console.info(...args)
    }
  }

  debug (...args) {
    if (this.level <= DEBUG) {
      console.log(...args)
    }
  }
}
