import { isEqualString } from './helpers.js'

export function createHandleAccountsChanged (sdk) {
  return (accounts) => {
    sdk.ports.receiveWalletAddress.send(selectAccount(sdk, accounts))
  }
}

export function selectAccount (sdk, accounts) {
  sdk.logger.debug({ accounts })

  if (accounts.length > 0) {
    const accountAddress = accounts[0]
    sdk.logger.debug({ accountAddress })

    return {
      tag: 'address',
      value: accountAddress
    }
  } else {
    sdk.logger.debug('No accounts found')

    return {
      tag: 'address',
      value: null
    }
  }
}

export function createHandleChainChanged (sdk) {
  return (chainId) => {
    if (!isTargetChain(sdk, chainId)) {
      window.location.reload()
    }
  }
}

export function connectWallet (sdk) {
  return async (setupEventHandlers = true, checkChainId = true) => {
    try {
      if (setupEventHandlers) {
        sdk.handleAccountsChanged = sdk.handleAccountsChanged || createHandleAccountsChanged(sdk)
        sdk.handleChainChanged = sdk.handleChainChanged || createHandleChainChanged(sdk)

        sdk.ethereum.on('accountsChanged', sdk.handleAccountsChanged)
        sdk.ethereum.on('chainChanged', sdk.handleChainChanged)
      }

      const chainId = await sdk.ethereum.chainId // .request({ method: 'eth_chainId' })
      // NOTE: I SHOULD NOT use sdk.ethereum.chainId but
      //       the preferred approach isn't giving me the
      //       behaviour I expect so I'm using this workaround
      //       for now.

      if (!checkChainId || isTargetChain(sdk, chainId)) {
        if (checkChainId) {
          sdk.logger.debug({ chainId })
        }

        const accounts = await sdk.ethereum.request({ method: 'eth_requestAccounts' })

        return selectAccount(sdk, accounts)
      } else {
        sdk.logger.debug({ chainId })

        await switchToTargetChain(sdk)()

        return await connectWallet(sdk)(false, false)
      }
    } catch (e) {
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: 'Unexpected problems encountered while connecting wallet.'
      }
    }
  }
}

export function disconnectWallet (sdk) {
  return async () => {
    sdk.ethereum.removeListener('chainChanged', sdk.handleChainChanged)
    sdk.ethereum.removeListener('accountsChanged', sdk.handleAccountsChanged)
  }
}

export function switchToTargetChain (sdk) {
  return async () => {
    try {
      await sdk.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: sdk.config.targetChain.id }]
      })
    } catch (e) {
      if (e.code === 4902 || // e on web
        (e.data && e.data.originalError && e.data.originalError.code === 4902) || // e on mobile app
        (e.data && e.data.cause && e.data.cause.code === 4902)
      ) {
        await sdk.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: sdk.config.targetChain.id,
              chainName: sdk.config.targetChain.name,
              nativeCurrency: {
                name: sdk.config.targetChain.nativeCurrencyName,
                symbol: sdk.config.targetChain.nativeCurrencySymbol,
                decimals: sdk.config.targetChain.nativeCurrencyDecimals
              },
              rpcUrls: sdk.config.targetChain.publicRpcUrls,
              blockExplorerUrls: sdk.config.targetChain.blockExplorerUrls
            }
          ]
        })
      } else {
        throw e
      }
    }
  }
}

export function getStakingValues (sdk) {
  return async ({ nonce, address }) => {
    try {
      const qodaPrice = (await sdk.getPriceOfQoda()).toElmValue()

      if (address === null) {
        return {
          tag: 'basic',
          value: {
            nonce,
            stakingValues: {
              qodaPrice
            }
          }
        }
      } else {
        const qodaUsdcUniV2Balance = await sdk.getQodaUsdcUniV2Balance(address, true)
        const qodaUsdcUniV2StakedBalance = await sdk.getQodaUsdcUniV2StakedBalance(address, true)

        const qodaWethUniV2Balance = await sdk.getQodaWethUniV2Balance(address, true)
        const qodaWethUniV2StakedBalance = await sdk.getQodaWethUniV2StakedBalance(address, true)

        const qodaBalance = await sdk.getQodaBalance(address, true)
        const qodaStakedBalance = await sdk.getQodaStakedBalance(address, true)

        const claimRewards = await sdk.getVeQodaOwnership(address)

        return {
          tag: 'full',
          value: {
            nonce,
            stakingValues: {
              qodaPrice,
              veQodaOwnership: Number(claimRewards.ownership),
              veQodaAccrual: claimRewards.accrual.toElmValue(),
              qodaUsdc: {
                walletBalance: {
                  uniV2: qodaUsdcUniV2Balance.uniV2.toElmValue(),
                  qoda: qodaUsdcUniV2Balance.qoda.toElmValue(),
                  usdc: qodaUsdcUniV2Balance.usdc.toElmValue(),
                  usd: qodaUsdcUniV2Balance.uniV2Usdc.toElmValue()
                },
                stakedBalance: {
                  uniV2: qodaUsdcUniV2StakedBalance.uniV2.toElmValue(),
                  qoda: qodaUsdcUniV2StakedBalance.qoda.toElmValue(),
                  usdc: qodaUsdcUniV2StakedBalance.usdc.toElmValue(),
                  usd: qodaUsdcUniV2StakedBalance.uniV2Usdc.toElmValue()
                }
              },
              qodaWeth: {
                walletBalance: {
                  uniV2: qodaWethUniV2Balance.uniV2.toElmValue(),
                  qoda: qodaWethUniV2Balance.qoda.toElmValue(),
                  weth: qodaWethUniV2Balance.weth.toElmValue(),
                  usd: qodaWethUniV2Balance.uniV2Usdc.toElmValue()
                },
                stakedBalance: {
                  uniV2: qodaWethUniV2StakedBalance.uniV2.toElmValue(),
                  qoda: qodaWethUniV2StakedBalance.qoda.toElmValue(),
                  weth: qodaWethUniV2StakedBalance.weth.toElmValue(),
                  usd: qodaWethUniV2StakedBalance.uniV2Usdc.toElmValue()
                }
              },
              qoda: {
                walletBalance: {
                  qoda: qodaBalance.balance.toElmValue(),
                  usd: qodaBalance.balanceUSDC.toElmValue()
                },
                stakedBalance: {
                  qoda: qodaStakedBalance.balance.toElmValue(),
                  usd: qodaStakedBalance.balanceUSDC.toElmValue()
                }
              }
            }
          }
        }
      }
    } catch (e) {
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: 'Unexpected problems encountered while getting staking values.'
      }
    }
  }
}

export function getQodaBalance (sdk) {
  return async (address) => {
    try {
      const { balance } = await sdk.getQodaBalance(address)

      return {
        tag: 'balance',
        value: balance.toElmValue()
      }
    } catch (e) {
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: `Unexpected problems encountered while getting the QODA balance for ${address}.`
      }
    }
  }
}

export function getQodaStakedBalance (sdk) {
  return async (address) => {
    try {
      const { balance } = await sdk.getQodaStakedBalance(address)

      return {
        tag: 'balance',
        value: balance.toElmValue()
      }
    } catch (e) {
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: `Unexpected problems encountered while getting the QODA staked balance for ${address}.`
      }
    }
  }
}

export function getUniV2Balance (sdk) {
  return async ({ liquidityPool, address }) => {
    try {
      let uniV2 = null
      if (liquidityPool === 'qoda-usdc') {
        uniV2 = (await sdk.getQodaUsdcUniV2Balance(address)).uniV2
      } else if (liquidityPool === 'qoda-weth') {
        uniV2 = (await sdk.getQodaWethUniV2Balance(address)).uniV2
      } else {
        throw new Error(`Unknown liquidity pool: ${liquidityPool}`)
      }

      return {
        tag: 'balance',
        value: uniV2.toElmValue()
      }
    } catch (e) {
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: `Unexpected problems encountered while getting the UNI-V2 balance for ${address}.`
      }
    }
  }
}

export function getUniV2StakedBalance (sdk) {
  return async ({ liquidityPool, address }) => {
    try {
      let uniV2 = null
      if (liquidityPool === 'qoda-usdc') {
        uniV2 = (await sdk.getQodaUsdcUniV2StakedBalance(address)).uniV2
      } else if (liquidityPool === 'qoda-weth') {
        uniV2 = (await sdk.getQodaWethUniV2StakedBalance(address)).uniV2
      } else {
        throw new Error(`Unknown liquidity pool: ${liquidityPool}`)
      }

      return {
        tag: 'balance',
        value: uniV2.toElmValue()
      }
    } catch (e) {
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: `Unexpected problems encountered while getting the UNI-V2 staked balance for ${address}.`
      }
    }
  }
}

export function stakeQoda (sdk) {
  return async ({ address, amount }) => {
    try {
      const signer = await sdk.browserProvider.getSigner()

      let approveTx = null
      let stakeTx = null

      try {
        approveTx = await sdk.approveVeQodaToTransferQoda(signer, amount.value)
        if (approveTx) {
          sdk.logger.info('approveTx', approveTx)
        }
      } catch (e) {
        sdk.logger.error(e)

        return {
          tag: 'approveError',
          value: 'Unexpected problems encountered while approving veQODA to spend QODA.'
        }
      }

      const approveTxHash = approveTx?.hash || null

      try {
        stakeTx = await sdk.stakeQoda(signer, address, amount.value)
        sdk.logger.info('stakeTx', stakeTx)

        return {
          tag: 'success',
          value: {
            approveTxHash,
            stakeTxHash: stakeTx.hash
          }
        }
      } catch (e) {
        sdk.logger.error(e)

        return {
          tag: 'stakeError',
          value: {
            message: 'Unexpected problems encountered while staking QODA with vanilla method.',
            approveTxHash
          }
        }
      }
    } catch (e) {
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: 'Unexpected problems encountered while staking QODA.'
      }
    }
  }
}

export function unstakeQoda (sdk) {
  return async (amount) => {
    try {
      const signer = await sdk.browserProvider.getSigner()

      const unstakeTx = await sdk.unstakeQoda(signer, amount.value)
      sdk.logger.info('unstakeTx', unstakeTx)

      return {
        tag: 'success',
        value: {
          unstakeTxHash: unstakeTx.hash
        }
      }
    } catch (e) {
      sdk.customErrors.parseError(e)
      sdk.logger.error(e.parsedData)
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: 'Unexpected problems encountered while unstaking QODA.'
      }
    }
  }
}

export function stakeUniV2 (sdk) {
  return async ({ liquidityPool, address, amount }) => {
    try {
      const signer = await sdk.browserProvider.getSigner()

      let approveTx = null
      let stakeTx = null

      try {
        if (liquidityPool === 'qoda-usdc') {
          approveTx = await sdk.approveVeQodaToTransferUniV2(signer, sdk.qodaUsdcUniV2Token, amount.value)
        } else if (liquidityPool === 'qoda-weth') {
          approveTx = await sdk.approveVeQodaToTransferUniV2(signer, sdk.qodaWethUniV2Token, amount.value)
        } else {
          throw new Error(`Unknown liquidity pool: ${liquidityPool}`)
        }

        if (approveTx) {
          sdk.logger.info('approveTx', approveTx)
        }
      } catch (e) {
        sdk.logger.error(e)

        return {
          tag: 'approveError',
          value: 'Unexpected problems encountered while approving veQODA to spend UNI-V2.'
        }
      }

      const approveTxHash = approveTx?.hash || null

      try {
        if (liquidityPool === 'qoda-usdc') {
          stakeTx = await sdk.stakeQodaUsdcUniV2(signer, address, amount.value)
        } else if (liquidityPool === 'qoda-weth') {
          stakeTx = await sdk.stakeQodaWethUniV2(signer, address, amount.value)
        }
        sdk.logger.info('stakeTx', stakeTx)

        return {
          tag: 'success',
          value: {
            approveTxHash,
            stakeTxHash: stakeTx.hash
          }
        }
      } catch (e) {
        sdk.customErrors.parseError(e)
        sdk.logger.error(e.parsedData)
        sdk.logger.error(e)

        return {
          tag: 'stakeError',
          value: {
            message: 'Unexpected problems encountered while staking UNI-V2.',
            approveTxHash
          }
        }
      }
    } catch (e) {
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: 'Unexpected problems encountered while staking UNI-V2.'
      }
    }
  }
}

export function unstakeUniV2 (sdk) {
  return async ({ liquidityPool, amount }) => {
    try {
      const signer = await sdk.browserProvider.getSigner()

      let unstakeTx = null
      if (liquidityPool === 'qoda-usdc') {
        unstakeTx = await sdk.unstakeQodaUsdcUniV2(signer, amount.value)
      } else if (liquidityPool === 'qoda-weth') {
        unstakeTx = await sdk.unstakeQodaWethUniV2(signer, amount.value)
      } else {
        throw new Error(`Unknown liquidity pool: ${liquidityPool}`)
      }

      sdk.logger.info('unstakeTx', unstakeTx)

      return {
        tag: 'success',
        value: {
          unstakeTxHash: unstakeTx.hash
        }
      }
    } catch (e) {
      sdk.customErrors.parseError(e)
      sdk.logger.error(e.parsedData)
      sdk.logger.error(e)

      return {
        tag: 'error',
        value: 'Unexpected problems encountered while unstaking UNI-V2.'
      }
    }
  }
}

// HELPERS

function isTargetChain (sdk, chainId) {
  return isEqualString(chainId, sdk.config.targetChain.id)
}
