import { ethers } from 'ethers'
import ABI from '../ABI.js'

export default class RewardDistributor {
  constructor (provider, address) {
    this.contract = new ethers.Contract(address, ABI.RewardDistributor, provider)
  }

  // Read

  async getEpoch (timestamp) {
    return await this.contract.getEpoch(timestamp)
  }

  async getTimestamp (epoch) {
    return await this.contract.getTimestamp(epoch)
  }

  async getUnclaimedReward (account, epochTarget) {
    return await this.contract.getUnclaimedReward(account, epochTarget)
  }

  // Write

  async claimReward (signer, account, epochTarget) {
    const contract = this.contract.connect(signer)
    const tx = await contract.claimReward(account, epochTarget)

    return await tx.wait()
  }
}
