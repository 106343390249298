import { ethers } from 'ethers'
import ABI from '../ABI.js'
import UniswapV2Pair from './UniswapV2Pair.js'

export default class UniswapV2Factory {
  constructor (provider, address) {
    this.provider = provider
    this.contract = new ethers.Contract(address, ABI.UniswapV2Factory, provider)
  }

  // Read

  async getPair (tokenA, tokenB) {
    const lpTokenAddress = await this.contract.getPair(tokenA, tokenB)

    return new UniswapV2Pair(this.provider, lpTokenAddress)
  }
}
