import { ethers } from 'ethers'
import memoize from 'lodash/memoize.js'
import ABI from '../ABI.js'
import UniswapV2Factory from './UniswapV2Factory.js'

export default class UniswapV2Router02 {
  constructor (provider, address) {
    this.provider = provider
    this.contract = new ethers.Contract(address, ABI.UniswapV2Router02, provider)

    this.factory = memoize(this.factory)
  }

  // Read

  async factory () {
    const address = await this.contract.factory()

    return new UniswapV2Factory(this.provider, address)
  }

  async getAmountsOut (amountIn, path) {
    if (amountIn === 0n) {
      return path.map(_ => 0n)
    }

    try {
      return await this.contract.getAmountsOut(amountIn, path)
    } catch (error) {
      const reason = error.reason || ''

      if (reason.match(/INSUFFICIENT_LIQUIDITY/)) {
        return path.map(_ => 0n)
      } else {
        throw error
      }
    }
  }
}
