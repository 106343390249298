import { ethers } from 'ethers'
import memoize from 'lodash/memoize.js'
import ABI from '../ABI.js'

export default class ERC20 {
  constructor (provider, address, extendedAbi) {
    this.address = address
    this.contract = new ethers.Contract(
      address,
      extendedAbi ? ABI.ERC20.concat(extendedAbi) : ABI.ERC20,
      provider
    )

    this.decimals = memoize(this.decimals)
    this.name = memoize(this.name)
    this.symbol = memoize(this.symbol)
    this.totalSupply = memoize(this.totalSupply)
  }

  // Read

  async balanceOf (account) {
    return await this.contract.balanceOf(account)
  }

  async decimals () {
    return await this.contract.decimals()
  }

  async name () {
    return await this.contract.name()
  }

  async symbol () {
    return await this.contract.symbol()
  }

  async totalSupply () {
    return await this.contract.totalSupply()
  }

  async allowance (owner, spender) {
    return await this.contract.allowance(owner, spender)
  }

  // Write

  async approve (signer, spender, amount) {
    const contract = this.contract.connect(signer)
    const tx = await contract.approve(spender, amount)

    return await tx.wait()
  }
}
