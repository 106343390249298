export default {
  common: {
    targetChain: {
      id: '0xA4B1',
      name: 'Arbitrum One',
      nativeCurrencyDecimals: 18,
      nativeCurrencyName: 'Arbitrum',
      nativeCurrencySymbol: 'ETH',
      publicRpcUrls: [
        'https://arbitrum-one.publicnode.com',
        'https://arbitrum-one.public.blastapi.io'
      ],
      blockExplorerUrls: [
        'https://arbiscan.io'
      ]
    },
    rpcUrl: 'https://arbitrum-one.blastapi.io/3b5dcc5d-d48f-4742-b4f1-1e048498ca19'
  },
  staging: {
    logLevel: 'DEBUG',
    contracts: {
      qodaToken: '0xc58d3032f51972A621545fdD2b9eCf60FC21805c',
      rewardDistributor: '0xaC48A056b941B3F95C20971235dCBC837C2ad955',
      uniswapV2Router02: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
      usdcToken: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      veQoda: '0x4A378ca329482319348c74F092e65109458AcEFf',
      wethToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
    }
  },
  production: {
    logLevel: 'INFO',
    contracts: {
      qodaToken: '0x763a716dD74a79d037E57f993fe3047271879bc1',
      rewardDistributor: null,
      uniswapV2Router02: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
      usdcToken: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      veQoda: '0x12B4CD0B518E5b085da877Fce853d40196747D49',
      wethToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
    }
  }
}
