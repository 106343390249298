//
// 2^256 - 1
//
export const MAX_EPOCH_TARGET = 115792089237316195423570985008687907853269984665640564039457584007913129639935n

//
// 1 day in seconds
//
export const ONE_DAY = 86400

//
// 1 QODA
//
export const ONE_QODA = 1000000000000000000n

//
// By default, it performs case-insensitive string equality.
//
export function isEqualString (a, b, ignoreCase = true) {
  const s = a || ''
  const t = b || ''

  if (ignoreCase) {
    return s.toLowerCase() === t.toLowerCase()
  }
  return s === t
}

//
// Returns the current time in seconds.
//
export function nowInSeconds () {
  return Math.floor(Date.now() / 1000)
}

export function pow10Exponent (n) {
  //
  // Assumes n is a positive integer power of 10, i.e. n = 10^p where 0 < p < 80.
  //
  // Why 80? Because n < 2^256 < 10^80.
  //
  // It returns p or null.
  //
  if (n >= 1n) {
    let exponent = 0n
    while (n > 1n && exponent < 80n) {
      n /= 10n
      exponent++
    }
    return exponent === 80n ? null : exponent
  }
  return null
}
