export default class CurrencyConverter {
  constructor (contract) {
    //
    // contract - Any contract that has a getAmountsOut function.
    //            For e.g. UniswapV2Router.
    //
    this.contract = contract
  }

  async convert (amount, fromToken, toToken) {
    const result = await this.contract.getAmountsOut(amount, [fromToken, toToken])

    return result[1]
  }
}
