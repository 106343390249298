import arbitrumOne from './config/networks/arbitrumOne.js'

const configurations = {
  staging: {
    networks: {
      'arbitrum-one': {
        ...arbitrumOne.common,
        ...arbitrumOne.staging
      }
    }
  },
  production: {
    networks: {
      'arbitrum-one': {
        ...arbitrumOne.common,
        ...arbitrumOne.production
      }
    }
  }
}

export default function initConfig (env, network) {
  const possibleEnvs = Object.keys(configurations)
  const envConfig = configurations[env]

  if (envConfig) {
    const possibleNetworks = Object.keys(envConfig.networks)
    const networkConfig = envConfig.networks[network]

    if (networkConfig) {
      return networkConfig
    } else {
      throw new Error(`Expected network to be one of: ${possibleNetworks.join(', ')}`)
    }
  } else {
    throw new Error(`Expected env to be one of: ${possibleEnvs.join(', ')}`)
  }
}
