import memoize from 'lodash/memoize.js'
import ABI from '../ABI.js'
import ERC20 from './ERC20.js'

export default class UniswapV2Pair extends ERC20 {
  constructor (provider, address) {
    super(provider, address, ABI.UniswapV2Pair)

    this.token0 = memoize(this.token0)
    this.token1 = memoize(this.token1)
  }

  // Read

  async token0 () {
    return await this.contract.token0()
  }

  async token1 () {
    return await this.contract.token1()
  }

  async getReserves () {
    const reserves = await this.contract.getReserves()

    return {
      reserve0: reserves[0],
      reserve1: reserves[1],
      blockTimestampLast: reserves[2]
    }
  }
}
